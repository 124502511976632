export const environment = {
  baseUrl: '',
  production: true,
  sentryDns: 'https://077f6ac991ef02722ea24c1c0dfe02a7@sentry.mibcon.cz:32793/2',
  sentryRelease: 'b9b31eb3',
  enableLogToConsole: true,
  cmsEndpoint: '/api/cms',
  b2cEndpoint: '/api/b2c',
  gMapsKey: 'AIzaSyC77EASqTBaRufVzKFAaXylyevK9BnDr5M',
  facebookDomainVerificationCode: 'c65hw5dt61vjaxag5vp37kvyf3jdxx',
  facebookPixelCodes: ['985641735994226', '651270947048897'],
  seznamWmt: 'FLsbvF6NTadGsPcwCR1YbaMP4IX5uOyz',
  cookieScriptId: 'd4d9ee96c200abaaf7bf99746820e78d',
  heurekaWidgetKey: '35510177A2FCA02DBE635CD981BDD392'
};
